import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'

const CareerPage = () => (
  <Layout>
    <SEO title="Aginix Career" />
    <h1>Career Page</h1>
  </Layout>
)

export default CareerPage
